export async function getAllUsers(axios, config, token) {
  const options = {
    headers: {
      Authorization: token
    }
  };
  const result = await axios.get(`${config.auth_api}/auth/all-users`, options);
  function IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  if (result.status === 200) {
    result.data.users = result.data.users.map((e) => {
      if (IsJsonString(e.roleInfo)) {
        try {
          e.roleInfo = JSON.parse(e.roleInfo);
        } catch (error) {
          console.log(e.roleInfo);
        }
      } else {
        e.roleInfo = {};
      }

      if (IsJsonString(e.publicRoleInfo)) {
        try {
          e.publicRoleInfo = JSON.parse(e.publicRoleInfo);
        } catch (error) {
          console.log("");
        }
      } else {
        e.publicRoleInfo = {};
      }
      return e;
    });
  }

  return result;
}

export async function approveUser(axios, config, userid, token, payload) {
  const opt = {
    headers: {
      Authorization: token
    }
  };

  const result = await axios.put(`${config.auth_api}/auth/approve-user/${userid}`, payload, opt).then(
    (response) => {
      return response;
    },
    (err) => {
      return err.response;
    }
  );

  return result;
}

export async function lockUser(axios, config, userid, token, payload) {
  const opt = {
    headers: {
      Authorization: token
    }
  };

  const result = await axios.put(`${config.auth_api}/auth/lock-user/${userid}`, payload, opt).then(
    (response) => {
      return response;
    },
    (err) => {
      return err.response;
    }
  );

  return result;
}

export async function autoApproveUser(axios, config, autoApprove, password, token) {
  const payload = {
    password: password,
    enable: autoApprove
  };
  const options = {
    headers: {
      Authorization: token
    }
  };
  const result = await axios.post(`${config.auth_api}/auth/auto-approve`, payload, options).then(
    (response) => {
      return response;
    },
    (err) => {
      return err.response;
    }
  );

  return result;
}

export async function getAutoApproveStatus(axios, config, token) {
  try {
    const options = {
      headers: {
        Authorization: token
      }
    };
    const result = await axios.get(`${config.auth_api}/auth/auto-approve`, options);

    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function getAllWorks(axios, config, token) {
  const options = {
    headers: {
      Authorization: token
    }
  };
  const result = await axios.get(`${config.works_api}/works/all`, options);

  if (result.status === 200) {
    if (result.data) {
      result.data = result.data.map((elem) => {
        try {
          if (elem.workInfo === "") {
            elem.workInfo = "{}";
          }
          elem.workInfo = JSON.parse(elem.workInfo);
          if (elem.unmanagedInfo === "") {
            elem.unmanagedInfo = "{}";
          }
          elem.unmanagedInfo = JSON.parse(elem.unmanagedInfo);

          return elem;
        } catch (error) {
          (error) => error;
        }
      });
    }
  }

  return result;
}

export async function approveWork(axios, config, workid, token, payload) {
  const opt = {
    headers: {
      Authorization: token
    }
  };

  const result = await axios.put(`${config.works_api}/works/approve-publish/${workid}`, payload, opt).then(
    (response) => {
      return response;
    },
    (err) => {
      return err.response;
    }
  );

  return result;
}

export async function hideWork(axios, config, workid, token, payload) {
  const opt = {
    headers: {
      Authorization: token
    }
  };

  const result = await axios.put(`${config.works_api}/works/hide/${workid}`, payload, opt).then(
    (response) => {
      return response;
    },
    (err) => {
      return err.response;
    }
  );

  return result;
}

export async function setAsLanding(axios, config, workid, token, payload) {
  const opt = {
    headers: {
      Authorization: token
    }
  };

  const result = await axios.put(`${config.works_api}/works/landing/${workid}`, payload, opt).then(
    (response) => {
      return response;
    },
    (err) => {
      return err.response;
    }
  );

  return result;
}

export async function createNewEvent(axios, config, token, payload) {
  const opt = {
    headers: {
      Authorization: token
    }
  };

  const result = await axios.post(`${config.events_api}/events`, payload, opt).then(
    (response) => {
      return response;
    },
    (err) => {
      return err.response;
    }
  );
  return result;
}

export async function editEvent(axios, config, token, payload, eventCode, keypair) {
  const opt = {
    headers: {
      Authorization: token
    }
  };

  payload.privateKey = keypair.privateKey;
  payload.publicKey = keypair.publicKey;

  const result = await axios.put(`${config.events_api}/events/${eventCode}`, payload, opt).then(
    (response) => {
      return response;
    },
    (err) => {
      return err.response;
    }
  );
  return result;
}

export async function viewEventWorks(axios, config, token, eventCode) {
  const opt = {
    headers: {
      Authorization: token
    }
  };
  try {
    const result = await axios.get(`${config.events_api}/events/${eventCode}`, opt);

    if (result.status === 200) {
      result.data = result.data.map((elem) => {
        try {
          if (elem.workInfo === "") {
            elem.workInfo = "{}";
          }
          elem.workInfo = JSON.parse(elem.workInfo);
          if (elem.unmanagedInfo === "") {
            elem.unmanagedInfo = "{}";
          }
          elem.unmanagedInfo = JSON.parse(elem.unmanagedInfo);

          return elem;
        } catch (error) {
          (error) => error;
        }
      });
    }
    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function getAllReferral(axios, config, token) {
  const opt = {
    headers: {
      Authorization: token
    }
  };

  const result = await axios.get(`${config.auth_api}/auth/referral`, opt).then(
    (response) => {
      return response;
    },
    (err) => {
      return err.response;
    }
  );
  return result;
}

export async function createNewReferral(axios, config, token, payload) {
  const opt = {
    headers: {
      Authorization: token
    }
  };

  const result = await axios.post(`${config.auth_api}/auth/referral`, payload, opt).then(
    (response) => {
      return response;
    },
    (err) => {
      return err.response;
    }
  );
  return result;
}

export async function createNewLandingInfo(axios, config, token, landingInfo) {
  const opt = {
    headers: {
      Authorization: token
    }
  };

  const payload = {
    landingInfo
  };

  const result = await axios.post(`${config.events_api}/landing-info`, payload, opt).then(
    (response) => {
      return response;
    },
    (err) => {
      return err.response;
    }
  );
  return result;
}

export async function editLandingInfo(axios, config, token, landingInfo, infoid) {
  const opt = {
    headers: {
      Authorization: token
    }
  };

  const payload = {
    landingInfo
  };

  const result = await axios.put(`${config.events_api}/landing-info/${infoid}`, payload, opt).then(
    (response) => {
      return response;
    },
    (err) => {
      return err.response;
    }
  );
  return result;
}

export async function deleteLandingInfo(axios, config, token, infoid) {
  const opt = {
    headers: {
      Authorization: token
    }
  };

  const result = await axios.delete(`${config.events_api}/landing-info/${infoid}`, opt).then(
    (response) => {
      return response;
    },
    (err) => {
      return err.response;
    }
  );
  return result;
}

export async function getAllOrders(axios, config, token) {
  const options = {
    headers: {
      Authorization: token
    }
  };
  const result = await axios.get(`${config.backend_api}/orders/info`, options).then(
    (response) => {
      return response;
    },
    (err) => {
      return err.response;
    }
  );

  return result;
}

export async function getPaypalId(axios, config, token, orderid) {
  const options = {
    headers: {
      Authorization: token
    }
  };
  const result = await axios.get(`${config.backend_api}/orders/paypalid/${orderid}`, options).then(
    (response) => {
      return response.data.data.paypalOrderid[0];
    },
    (err) => {
      return err.response;
    }
  );

  return result;
}

export async function getPaypalDetails(axios, config, token, paypalid) {
  const options = {
    headers: {
      Authorization: token
    }
  };
  const result = await axios.get(`${config.backend_api}/payments/paypal/get-details/${paypalid}`, options).then(
    (response) => {
      return response;
    },
    (err) => {
      return err.response;
    }
  );

  return result;
}
