<template>
  <div>
    <MessageBox
      v-model="messageBox.state"
      :message-content="messageBox.content"
      :message-class="messageBox.cssClass"
      :destination="messageBox.destination"
    />
    <div id="displayCard">
      <v-card min-width="600px" class="pa-10">
        <h1>Referral</h1>
        <div class="searchToolbar">
          <v-text-field
            v-model="searchInput"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <div class="ma-3">
            <v-dialog v-model="dialog" persistent max-width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark depressed v-bind="attrs" v-on="on"> New Referral Code </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">Create new referral record</span>
                </v-card-title>
                <v-card-text>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          label="Referral Code"
                          required
                          v-model="referralCode"
                          :rules="referralCodeRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field label="Discount" v-model="discount" :rules="discountRules"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field label="Max Users" v-model="maxUsers" :rules="maxUserRules"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          :return-value.sync="expiryDate"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="expiryDate"
                              label="Expiry Date"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="expiryDate"
                            no-title
                            scrollable
                            :min="new Date().toISOString().substr(0, 10)"
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
                            <v-btn text color="primary" @click="$refs.menu.save(expiryDate)"> OK </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialog = false"> Close </v-btn>
                  <v-btn color="blue darken-1" text @click="createNewReferral"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </div>

        <v-data-table
          :headers="headers.filter((header) => header.inTable)"
          :items="referrals"
          :search="searchInput"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:item.expiryDate="{ item }">
            {{
              new Date(item.expiryDate).toLocaleString("en-GB", {
                day: "numeric",
                month: "short",
                year: "numeric",
                hour: "numeric",
                minute: "2-digit"
              })
            }}
          </template>
          <template v-slot:item.createdAt="{ item }">
            {{
              new Date(item.createdAt).toLocaleString("en-GB", {
                day: "numeric",
                month: "short",
                year: "numeric",
                hour: "numeric",
                minute: "2-digit"
              })
            }}
          </template>
          <template v-slot:item.registeredUsers="{ item }">
            <v-dialog v-model="item.registeredUserDialog" width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" v-if="item.registeredUsers.length > 0">
                  <v-icon> mdi-eye-outline</v-icon>
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="headline grey lighten-2"> User list </v-card-title>

                <v-card-text v-for="(user, i) in item.registeredUsers" :key="i" class="ma-2">
                  {{ user }}
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="item.registeredUserDialog = false"> Close</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:item.premiumUsers="{ item }">
            <v-dialog v-model="item.premiumUserDialog" width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" v-if="item.premiumUsers.length > 0">
                  <v-icon> mdi-eye-outline</v-icon>
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="headline grey lighten-2"> Premium user list </v-card-title>

                <v-card-text v-for="(user, i) in item.premiumUsers" :key="i" class="ma-2">
                  {{ user }}
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="item.premiumUserDialog = false"> Close</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
// import AddArtwork from "@/components/Platform/AddArtwork";

import PlatformLayout from "@/layouts/Platform";

import { createNewReferral } from "@/services/adminService";

import MessageBox from "@/components/Common/MessageBox";
import config from "@/config.js";
import { getAllReferral } from "@/services/adminService";
// import VueJsonToCsv from "vue-json-to-csv";

export default {
  name: "AdminReferralFunctions",
  components: {
    MessageBox
    // VueJsonToCsv
  },
  data: () => ({
    files: [],
    expand: false,
    openIdx: -1,
    sortBy: "createdAt",
    sortDesc: true,
    headers: [
      {
        text: "Referral Code",
        value: "referralCode",
        align: "left",
        sortable: false,
        inputField: false,
        inTable: true
      },
      {
        text: "Discount",
        align: "left",
        sortable: true,
        value: "discount",
        inTable: true
      },
      {
        text: "Max Users",
        align: "left",
        sortable: true,
        value: "maxUsers",
        inTable: true
      },
      {
        text: "Remaining Quota",
        align: "left",
        sortable: false,
        value: "remainingQuota",
        inTable: true
      },
      {
        text: "Created At",
        align: "left",
        sortable: true,
        value: "createdAt",
        inTable: true
      },
      {
        text: "Expiry Date",
        align: "left",
        sortable: true,
        value: "expiryDate",
        inTable: true
      },

      {
        text: "Registered Users",
        align: "left",
        sortable: false,
        value: "registeredUsers",
        inTable: true
      },
      {
        text: "Premium Users",
        align: "left",
        sortable: false,
        value: "premiumUsers",
        inTable: true
      }
    ],
    dates: ["2020-12-01"],
    referralCode: "",
    referrals: [],
    expiryDate: new Date().toISOString().substr(0, 10),
    discount: 1,
    maxUsers: 1,
    addrList: {},
    editedIndex: -1,
    timer: null,
    searchField: null,
    searchInput: null,
    addingArtwork: false,
    pageNumber: 1,
    itemsPerPage: 5,
    pageCount: 1,
    messageBox: {
      state: false,
      content: "",
      cssClass: "error",
      destination: ""
    },
    menu: false,
    dialog: false,
    registeredUserDialog: false,
    premiumUserDialog: false,
    viewItem: "",
    referralCodeRules: [(v) => !!v || "Field is required"],
    discountRules: [
      (v) => !!v || "Discount field is required",
      (v) => (v <= 1 && v >= 0) || "Discount field must be between 0 and 1."
    ],
    maxUserRules: [
      (v) => !!v || "Max User field is required",
      (v) => !isNaN(v) || "Max User field must be an integer",
      (v) => Number.parseInt(v) > 0 || "Max User field must be a positive integer"
    ],
    valid: false
  }),
  computed: {
    effectiveDate() {
      return new Date(this.dates[0]).getTime();
    }
  },
  mounted() {
    this.initialize();
  },
  async created() {
    this.$emit(`update:layout`, PlatformLayout);
    const role = this.$session.get("role");
    if (role !== "AN") {
      this.$router.push({ name: "Not Found" });
      return;
    }

    this.role = this.$session.get("role");
    if (this.referrals.length === 0 && this.role === "member") {
      this.timer = setInterval(() => {
        this.role = this.$session.get("role");

        if (this.role !== "member") {
          clearInterval(this.timer);
          this.initialize();
        }
      }, 1000);
    } else {
      // to deal with refresh
      clearInterval(this.timer);
      this.initialize();
    }
  },
  methods: {
    async initialize() {
      //  Get request to server
      const token = this.$session.get("token");
      const result = await getAllReferral(this.$axios, config, token);

      if (result.status === 200) {
        this.referrals = result.data;
      } else if (result.status === 500) {
        this.messageBox.content = this.$t("dialogMsg.myCollection.blockchainFail");
        this.messageBox.state = true;
        this.messageBox.destination = "";
        this.$store.commit("showProgressLine", false);
      } else {
        this.messageBox = {
          content: `Failed to retrieve user information. Error code: ${result.status}. Error message: ${result.message}.`,
          cssClass: "error",
          state: true,
          destination: ""
        };
      }
    },
    async createNewReferral() {
      this.$refs.form.validate();
      if (this.valid) {
        const payload = {
          referralCode: this.referralCode,
          discount: parseFloat(this.discount),
          maxUsers: parseFloat(this.maxUsers),
          expiryDate: new Date(this.expiryDate).getTime()
        };
        const token = this.$session.get("token");
        const result = await createNewReferral(this.$axios, config, token, payload);

        if (result.status === 200) {
          this.messageBox = {
            content: `New referral record created`,
            cssClass: "message",
            state: true,
            destination: ""
          };
          this.initialize();
        } else {
          this.messageBox = {
            content: `Failed to create new referral record. Error code: ${result.status}. Error message: ${result.data.message}.`,
            cssClass: "error",
            state: true,
            destination: ""
          };
        }

        this.dialog = false;
      }
    }
  }
};
</script>
